<i18n>
{
  "de": {
    "boilerHeading": "Boiler",
    "heatpumpHeading": "Wärmepumpen",
    "typeTitle": "Typ",
    "efficiencyTitle": "Nutzungsgrad",
    "heatpumpGradeTitle": "Gütegrad",
    "efficiencyInfoBox": "Verhältnis von Nutzenergie Nutzenergie und Endenergie. Beinhaltet Verluste der Wärmeerzeugung und Wärmeverteilung (Standardwerte in SIA 380:2015 Anhang J.2, Tabelle 13)",
    "efficiencyInfoBoxTitle": "Nutzungsgrad Heizung und Warmwasser"
  }
}
</i18n>

<template>
  <edit-container :bind-form-state="formState" @container="onFormEvent" class="edit-heating-types">
    <h2>{{ $t('boilerHeading') }}</h2>
    <detail-list class="boiler-table">
      <template #header>
        <span>{{ $t('typeTitle') }}</span>
        <span>{{ $t('efficiencyTitle') }} <info-box :title="$t('efficiencyInfoBoxTitle')">{{ $t('efficiencyInfoBox') }}</info-box></span>
      </template>
      <li v-for="heatingKey in heatingTypes" :key="heatingKey">
        <div>
          {{ $t(`_heatingTypes.${heatingKey}`) }}
        </div>
        <div>
          <numeric-input v-model="model[heatingKey].efficiency" optional :edit="isEditing" :min="0" :max="1" :units="''" />
        </div>
      </li>
    </detail-list>
  </edit-container>
</template>
<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin'
import EditContainer from '@/components/shared/forms/EditContainer'

import DetailList from '@/components/shared/lists/DetailList'
import NumericInput from '@/components/shared/forms/NumericInput'
import InfoBox from '@/components/shared/InfoBox'

export default {
  mixins: [
    ApiFormMixin
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation */
  ],
  computed: {
    heatingTypes () {
      return ['GAS', 'OIL', 'PELLET', 'WOOD_CHIPS', 'DISTRICT', 'LOCAL', 'ELECTRIC', 'SOLAR', 'HP_AIR', 'HP_GW', 'HP_EWS']
    }
  },
  components: {
    DetailList,
    NumericInput,
    InfoBox,
    EditContainer
  },
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      updateParams: 'portfolio/updateParams'
    }),
    resetModel () {
      this.model = this.portfolio.heating_types
    },
    async saveHandler () {
      await this.updateParams({
        id: this.portfolio.id,
        params: {
          'heating_types': this.model
        }
      })
    }
  }
}
</script>
